import React, { useState } from 'react'

// layouts
import Layout from 'src/layouts/BaseLayout'
import { Wrapper } from '../../style'

// components
import { Modal } from 'src/components/Modal'

// Sections
import Header from '../../cartao/sections/header/_index'
import AtualizarEndereco from '../../cartao/sections/atualizarEndereco/_index'
import CartaoInterMastercard from '../../limite-credito/sections/cartao-inter-mastercard/_index'
import OutroProblema from '../../cartao/sections/outroProblema/_index'
import AtivacaoModal from 'src/components/UI/Forms/InterAtivacaoClientesForm'
import AtivacaoModal1 from 'src/components/UI/Forms/InterAtivacaoClientesForm1'
import HelpCenter from '../../helpCenter/_index'

// hooks
import useDomReady from 'src/hooks/window/useDomReady'

import pageContext from './pageContext.json'

const Cartao = () => {
  const page = 'MEI'
  const domReady = useDomReady()
  const [ openModal, setOpenModal ] = useState(false)
  const [ openModal1, setOpenModal1 ] = useState(false)

  const openAccountPJFormModal = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <AtivacaoModal closeModal={() => setOpenModal(false)} />
    </Modal>
  )

  const openAccountPJFormModal1 = domReady && (
    <Modal isModalOpen={openModal1} setIsModalOpen={setOpenModal1} locationToRender={document.body}>
      <AtivacaoModal1 closeModal={() => setOpenModal1(false)} page={page} />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {openAccountPJFormModal}
        {openAccountPJFormModal1}
        <Header />
        <AtualizarEndereco />
        <CartaoInterMastercard setOpenModal1={setOpenModal1} page={page} />
        <OutroProblema
          setOpenModal={setOpenModal} setOpenModal1={setOpenModal1} page={page}
          deeplink='https://bancointer.onelink.me/nyEn/appibmei'
        />
        <HelpCenter bgColor='bg-white' />
      </Layout>
    </Wrapper>
  )
}

export default Cartao
